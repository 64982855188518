@font-face {
  font-family: "FuturaCyrillicLight";
  src: url("./assets/fonts/FuturaCyrillicLight.ttf") format("truetype");
  src: url("./assets/fonts/FuturaCyrillicLight.otf") format("opentype");
  src: url("./assets/fonts/FuturaCyrillicLight.woff") format("woff");
  src: url("./assets/fonts/FuturaCyrillicLight.woff2") format("woff2");
}

@font-face {
  font-family: "FuturaCyrillicMedium";
  src: url("./assets/fonts/FuturaCyrillicMedium.ttf") format("truetype");
  src: url("./assets/fonts/FuturaCyrillicMedium.otf") format("opentype");
  src: url("./assets/fonts/FuturaCyrillicMedium.woff") format("woff");
  src: url("./assets/fonts/FuturaCyrillicMedium.woff2") format("woff2");
}

@font-face {
  font-family: "FuturaCyrillicBold";
  src: url("./assets/fonts/FuturaCyrillicBold.ttf") format("truetype");
  src: url("./assets/fonts/FuturaCyrillicBold.otf") format("opentype");
  src: url("./assets/fonts/FuturaCyrillicBold.woff") format("woff");
  src: url("./assets/fonts/FuturaCyrillicBold.woff2") format("woff2");
}

.App {
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  background: #0d0d11;
  overflow: hidden;
}

* {
  scroll-behavior: smooth;
}

.homepage-container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.custom-btn {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: #ac9fff;
  color: #000;
  border: 1px solid #ac9fff;
}

.custom-btn .custom-btn-inner {
  position: relative;
  z-index: 99;
}

.custom-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #fff;
  transition: all 0.6s;
}

.custom-btn:hover {
  color: black;
}

.custom-btn:hover:after {
  width: 100%;
  background: #fff;
  color: black;
}

.gradient-text {
  background: linear-gradient(90deg, #ac9fff 0%, #ffffff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* header */

header {
  width: 100%;
  height: 104px;
  z-index: 999;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  background: transparent;
}

.shrink {
  height: 90px;
  background: #0d0d11;
  z-index: 999;
}

.image-animation {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.image-animation:hover {
  transition: transform 0.5s ease-in-out;
  transform: scale(1.2);
}

.center-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nav ul {
  list-style: none;
  text-align: center;
}
nav ul li {
  display: inline-block;
}
nav ul li a {
  display: block;
  padding: 7px 15px;
  text-decoration: none;
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.5s;
}

nav.shift ul li a {
  position: relative;
  z-index: 1;
}

nav.shift ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: ".";
  color: transparent;
  background: linear-gradient(90deg, #b514fd 1.42%, #5f75f5 97.8%);
  visibility: none;
  opacity: 0;
  z-index: -1;
  border-radius: 10px;
}
nav.shift ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.active-profile-sidebar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  transition: all 0.5s;
}

.not-active-profile-sidebar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -100%;
  width: 100%;
  background: #000;
  transition: all 0.5s;
}

.profile-sidebar-inner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* offering */

.offering-card-even {
  border: 0.3px solid rgba(172, 159, 255, 0.3);
  z-index: 2;
  position: relative;
}

.offering-card-even-border {
  position: relative;
  border: 0.3px solid rgba(172, 159, 255, 0.3);
}

.offering-card-even-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(172, 159, 255, 0.1);
  border: 1px solid #ac9fff;
  filter: blur(16px);
  z-index: 1;
  border-radius: 28px;
}

.offering-card-even-border3 {
  position: relative;
  border: 0.3px solid rgba(172, 159, 255, 0.3);
}

.offering-card-even-border3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(172, 159, 255, 0.1);
  border: 1px solid #ac9fff;
  filter: blur(16px);
  z-index: 1;
  border-radius: 38px;
  border-bottom-left-radius: 160px;
}

.offering-card-odd {
  border: 0.3px solid rgba(255, 255, 255, 0.3);
  z-index: 2;
  position: relative;
}

.offering-card-odd-border4 {
  position: relative;
  border: 0.3px solid rgba(255, 255, 255, 0.3);
}

.offering-card-odd-border4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #fff;
  filter: blur(16px);
  z-index: 1;
  border-radius: 38px;
}

.offering-card-odd-border {
  position: relative;
  border: 0.3px solid rgba(255, 255, 255, 0.3);
}

.offering-card-odd-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #fff;
  filter: blur(16px);
  z-index: 1;
  border-radius: 38px;
  border-top-right-radius: 160px;
}

/* about */

.about-image-bg {
  background: linear-gradient(180deg, #7361e1 0%, #656b7b 100%);
}

/* joining */

.joining-read-btn {
  background: #ac9fff12;
  border: 0.42px solid #ac9fff29;
}

@media screen and (min-width: 1920px) {
  .homepage-container-resp {
    padding-left: 200px;
    padding-right: 200px;
  }

  .offering-card-even-border3::before {
    border-radius: 38px;
    border-bottom-left-radius: 160px;
  }

  .offering-card-odd-border::before {
    border-radius: 38px;
    border-top-right-radius: 160px;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1801px) {
  .homepage-container-resp {
    padding-left: 170px;
    padding-right: 170px;
  }

  .offering-card-even-border3::before {
    border-radius: 38px;
    border-bottom-left-radius: 160px;
  }

  .offering-card-odd-border::before {
    border-radius: 38px;
    border-top-right-radius: 160px;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1701px) {
  .homepage-container-resp {
    padding-left: 150px;
    padding-right: 150px;
  }

  .offering-card-even-border3::before {
    border-radius: 38px;
    border-bottom-left-radius: 160px;
  }

  .offering-card-odd-border::before {
    border-radius: 38px;
    border-top-right-radius: 160px;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1536px) {
  .homepage-container-resp {
    padding-left: 120px;
    padding-right: 120px;
  }

  .offering-card-even-border3::before {
    border-radius: 38px;
    border-bottom-left-radius: 160px;
  }

  .offering-card-odd-border::before {
    border-radius: 38px;
    border-top-right-radius: 160px;
  }
}

@media screen and (max-width: 1535px) and (min-width: 1280px) {
  header {
    height: 95px;
  }
  .shrink {
    height: 85px;
  }

  .offering-card-even-border::before {
    border-radius: 20px;
  }

  .offering-card-even-border3::before {
    border-radius: 38px;
    border-bottom-left-radius: 100px;
  }

  .offering-card-odd-border4::before {
    border-radius: 20px;
  }

  .offering-card-odd-border::before {
    border-radius: 38px;
    border-top-right-radius: 100px;
  }
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  header {
    height: 95px;
  }

  .shrink {
    height: 85px;
  }

  .offering-card-even-border::before {
    border-radius: 20px;
  }

  .offering-card-even-border3::before {
    border-radius: 38px;
    border-bottom-left-radius: 100px;
  }

  .offering-card-odd-border4::before {
    border-radius: 20px;
  }

  .offering-card-odd-border::before {
    border-radius: 38px;
    border-top-right-radius: 100px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 900px) {
  header {
    height: 90px;
  }

  .shrink {
    height: 85px;
  }

  .offering-card-even-border::before {
    border-radius: 20px;
  }

  .offering-card-even-border3::before {
    border-radius: 20px;
    border-bottom-left-radius: 80px;
  }

  .offering-card-odd-border4::before {
    border-radius: 20px;
  }

  .offering-card-odd-border::before {
    border-radius: 20px;
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 899px) and (min-width: 768px) {
  header {
    height: 80px;
  }

  .shrink {
    height: 70px;
  }

  .offering-card-even-border::before {
    border-radius: 20px;
  }

  .offering-card-even-border3::before {
    border-radius: 20px;
    border-bottom-left-radius: 80px;
  }

  .offering-card-odd-border4::before {
    border-radius: 20px;
  }

  .offering-card-odd-border::before {
    border-radius: 20px;
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 426px) {
  header {
    height: 80px;
  }

  .shrink {
    height: 70px;
  }

  .offering-card-even-border::before {
    border-radius: 20px;
  }

  .offering-card-even-border3::before {
    border-radius: 20px;
    border-bottom-left-radius: 80px;
  }

  .offering-card-odd-border4::before {
    border-radius: 20px;
  }

  .offering-card-odd-border::before {
    border-radius: 20px;
    border-top-right-radius: 80px;
  }
}

@media screen and (max-width: 425px) {
  header {
    height: 80px;
  }

  .shrink {
    height: 70px;
  }

  .offering-card-even-border::before {
    border-radius: 20px;
  }

  .offering-card-even-border3::before {
    border-radius: 20px;
    border-bottom-left-radius: 80px;
  }

  .offering-card-odd-border4::before {
    border-radius: 20px;
  }

  .offering-card-odd-border::before {
    border-radius: 20px;
    border-top-right-radius: 80px;
  }
}

/* media queries for raodmap */

@media screen and (min-width: 1801px) {
  .roadmap-wrapper-resp {
    min-width: 1450px;
    max-width: 1450px;
    width: 1450px;
  }

  .roadmap-line-resp {
    margin-left: 11.7%;
  }

  .roadmap-line-img {
    width: 16.8%;
  }

  .roadmap-wrapper-resp2 {
    min-width: 1450px;
    max-width: 1450px;
    width: 1450px;
  }

  .roadmap-line-resp2 {
    margin-left: 26.6%;
  }

  .roadmap-line-img2 {
    width: 20.4%;
  }

  .roadmap-wrapper-resp3 {
    min-width: 1450px;
    max-width: 1450px;
    width: 1450px;
  }

  .roadmap-line-resp3 {
    margin-left: 41.6%;
  }

  .roadmap-line-img3 {
    width: 25.7%;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1701px) {
  .roadmap-wrapper-resp {
    min-width: 1400px;
    max-width: 1400px;
    width: 1400px;
  }

  .roadmap-line-resp {
    margin-left: 12%;
  }

  .roadmap-line-img {
    width: 17%;
  }

  .roadmap-wrapper-resp2 {
    min-width: 1400px;
    max-width: 1400px;
    width: 1400px;
  }

  .roadmap-line-resp2 {
    margin-left: 27%;
  }

  .roadmap-line-img2 {
    width: 20.5%;
  }

  .roadmap-wrapper-resp3 {
    min-width: 1400px;
    max-width: 1400px;
    width: 1400px;
  }

  .roadmap-line-resp3 {
    margin-left: 42%;
  }

  .roadmap-line-img3 {
    width: 25.9%;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1536px) {
  .roadmap-wrapper-resp {
    min-width: 1300px;
    max-width: 1300px;
    width: 1300px;
  }

  .roadmap-line-resp {
    margin-left: 13%;
  }

  .roadmap-line-img {
    width: 17.1%;
  }

  .roadmap-wrapper-resp2 {
    min-width: 1300px;
    max-width: 1300px;
    width: 1300px;
  }

  .roadmap-line-resp2 {
    margin-left: 28%;
  }

  .roadmap-line-img2 {
    width: 20.6%;
  }

  .roadmap-wrapper-resp3 {
    min-width: 1300px;
    max-width: 1300px;
    width: 1300px;
  }

  .roadmap-line-resp3 {
    margin-left: 43%;
  }

  .roadmap-line-img3 {
    width: 26.2%;
  }
}

@media screen and (max-width: 1535px) and (min-width: 1401px) {
  .roadmap-wrapper-resp {
    min-width: 1200px;
    max-width: 1200px;
    width: 1200px;
  }

  .roadmap-line-resp {
    margin-left: 11.4%;
  }

  .roadmap-line-img {
    width: 19.1%;
  }

  .roadmap-wrapper-resp2 {
    min-width: 1200px;
    max-width: 1200px;
    width: 1200px;
  }

  .roadmap-line-resp2 {
    margin-left: 28.4%;
  }

  .roadmap-line-img2 {
    width: 23.55%;
  }

  .roadmap-wrapper-resp3 {
    min-width: 1200px;
    max-width: 1200px;
    width: 1200px;
  }

  .roadmap-line-resp3 {
    margin-left: 45.3%;
  }

  .roadmap-line-img3 {
    width: 31%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1280px) {
  .roadmap-wrapper-resp {
    min-width: 1100px;
    max-width: 1100px;
    width: 1100px;
  }

  .roadmap-line-resp {
    margin-left: 12.5%;
  }

  .roadmap-line-img {
    width: 19.1%;
  }

  .roadmap-wrapper-resp2 {
    min-width: 1100px;
    max-width: 1100px;
    width: 1100px;
  }

  .roadmap-line-resp2 {
    margin-left: 29.4%;
  }

  .roadmap-line-img2 {
    width: 23.9%;
  }

  .roadmap-wrapper-resp3 {
    min-width: 1100px;
    max-width: 1100px;
    width: 1100px;
  }

  .roadmap-line-resp3 {
    margin-left: 46.4%;
  }

  .roadmap-line-img3 {
    width: 31.6%;
  }
}
